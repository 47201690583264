import React from "react";
// import palette from "../util/palette.js";

// Barchart component
import PieChart from "./charts/PieChart";

const nums = [10, 50, 30, 20, 40, 60, 70];
const labels = ["A", "B", "C", "D", "E", "F", "G"];

const TrendingDrugs = ({ commonDrugs }) => {
  const palette = [
    "#1CA3F0",
    "#F08021",
    "#E5E5E5",
    "#3E517A",
    "#333333",
    "#F39D53",
    "#747474",
    "#004AAD",
    "#8ED1F8",
    "#F1B11E",
  ];

  const getData = () => {
    let data = {
      datasets: [
        {
          data: nums,
          backgroundColor: palette.slice(0, nums.length),
        },
      ],
      labels: labels,
    };
    return data;
  };

  // const getLocationData = () => {
  //   const data = commonDrugs.map((drug) => drug.total);
  //   return data;
  // };

  // const getLabels = () => {
  //   const data = commonDrugs.map((drug) => drug._id);
  //   return data;
  // };

  return (
    <>
      {nums.length == 0 ? (
        <div
          className='d-flex justify-content-center align-items-center h-100 bg-light'
          style={{ minWidth: "90%" }}
        >
          No data
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <PieChart data={getData()} />
        </div>
      )}
    </>
  );
};

export default TrendingDrugs;
