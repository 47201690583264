import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecentSamples, useAdverse } from "../api";
import { useUserLocations } from "../api/user";
import { useParamsContext } from "../contexts";
import Footer from "../components/Footer";
import Navbar from "../components/SpectraNavbar";
import { CardA, CardC } from "../components/ui/Cards";
import { SequenceD } from "../components/ui/Sequence";
import { ColourCircleC } from "../components/ui/ColourCircle";
import { DateTime } from "luxon";
import { find } from "lodash";
import { CSVLink } from "react-csv";

export default function RecentSamples() {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState({
    start: sevenDaysAgo,
    end: today,
  });
  const [selectedLocation, setSelectedLocation] = useState("All");
  const { data: adverse, isSuccess: adverseSuccess } = useAdverse();
  const { getColour, getTexture } = useParamsContext();
  const { data: locations } = useUserLocations();

  let headers = [
    { label: "Sample ID", key: "sampleId" },
    { label: "Location", key: "location" },
    { label: "Date", key: "processedDate" },
    { label: "Time", key: "time" },
    { label: "Scan Mode", key: "scanMode" },
    { label: "Graphitization Quality", key: "graphitizationQuality" },
    { label: "Notes", key: "notes" },
  ];

  const handleDateRangeChange = (event) => {
    const selectedDateRange = parseInt(event.target.value, 10);
    let endDate = new Date(); // Current date
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - selectedDateRange);
    setDateRange({ start: startDate, end: endDate });
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const {
    data: recentSamples,
    isLoading,
    isSuccess,
    isError,
  } = useRecentSamples(dateRange, selectedLocation);

  let tableData =
    isLoading || isError
      ? []
      : recentSamples.sequences.map((sample) => {
          let newItem = {};

          let localDate = DateTime.fromISO(sample.processedDate).setZone(
            "local"
          );

          newItem.processedDate = localDate.toFormat("DDD");

          newItem.time = localDate.toFormat("t");

          newItem.scanMode = sample.tests[0].scanMode
            ? sample.tests[0].scanMode.charAt(0).toUpperCase() +
              sample.tests[0].scanMode.slice(1)
            : "N/A";

          newItem.graphitizationQuality =
            sample.tests[0].graphite_quality || "N/A";

          newItem._id = sample._id;
          newItem.sampleId = sample.sampleId;
          newItem.location = locations.find(
            (location) => location.locationId === sample.locationId
          ).name;

          newItem.notes = sample.description;

          return newItem;
        });

  useEffect(() => {
    if (locations && locations.length > 1) {
      setSelectedLocation("All");
    } else if (locations && locations.length === 1) {
      setSelectedLocation(locations[0].locationId);
    }
  }, [locations]);

  const calculateSelectedValue = () => {
    // Calculate the number of days in the date range
    //NOTE: When there is a time change in the date range, the calculation will be off by an hour,so we round it to the nearest whole number
    const daysDifference = Math.round(
      (dateRange.end - dateRange.start) / (1000 * 60 * 60 * 24)
    );

    // Find the corresponding option value
    switch (daysDifference) {
      case 7:
        return 7;
      case 14:
        return 14;
      case 30:
        return 30;
      case 60:
        return 60;
      case 90:
        return 90;
      default:
        return 0; // Default to 0 if it doesn't match any option
    }
  };

  if (isLoading) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div
            className='alert  mx-3'
            role='alert'
            style={{ backgroundColor: "#7ECBF7", fontWeight: "bold" }}
          >
            Fetching samples ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (isError) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div className='alert alert-danger mx-3' role='alert'>
            Something went wrong, try refreshing the page
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className='flex-container'>
      <Navbar signedIn={false} />
      <div
        className='px-sm-3 px-lg-5 py-3 flex-body'
        style={{ marginTop: "79px" }}
      >
        {isSuccess && (
          <CardA
            title='Recent Samples'
            subtitle='Click on a row to view and edit the sample.'
          >
            <div className='d-flex mb-3 justify-content-between '>
              <div className='d-flex'>
                <select
                  className='form-select w-auto'
                  id='dateRangeSelect'
                  value={calculateSelectedValue()}
                  onChange={handleDateRangeChange}
                >
                  <option value={7}>Past 7 Days</option>
                  <option value={14}>Past 14 Days</option>
                  <option value={30}>Past 30 Days</option>
                  <option value={60}>Past 60 Days</option>
                  <option value={90}>Past 90 Days</option>
                </select>

                {locations.length > 1 && (
                  <select
                    className='form-select w-auto'
                    id='locationSelect'
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <option key={"All"} value={"All"}>
                      All Locations
                    </option>
                    {locations.map((location) => (
                      <option
                        key={location.locationId}
                        value={location.locationId}
                      >
                        {location.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <CSVLink
                data={tableData}
                headers={headers}
                filename={`Samples ${dateRange.start
                  .toISOString()
                  .slice(0, 10)} to ${today.toISOString().slice(0, 10)}.csv`}
                className='btn btn-outline-primary'
              >
                Export to CSV
              </CSVLink>
            </div>
            {tableData.length === 0 ? (
              <div className='d-flex justify-content-center align-items-center flex-column flex-grow-1'>
                <i className='bi bi-search fs-1'></i>
                <h5>No samples found</h5>
                <h6>Please adjust the filter settings.</h6>
              </div>
            ) : (
              <div className='table-responsive flex-grow-1'>
                <table className='table table-striped table-hover table-bordered'>
                  <thead>
                    <tr className='text-center'>
                      <th scope='col' className='p-1'>
                        Sample ID
                      </th>
                      <th scope='col' className='p-1' style={{ width: "15%" }}>
                        Location
                      </th>
                      <th scope='col' className='p-1'>
                        Date
                      </th>
                      <th scope='col' className='p-1'>
                        Time
                      </th>
                      <th scope='col' className='p-1'>
                        Scan Mode
                      </th>

                      <th scope='col' className='p-1'>
                        Graphitization Quality
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((sample) => (
                      <tr
                        key={sample._id}
                        onClick={() =>
                          (window.location = `/sample/${sample._id}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td className='text-center align-middle'>
                          {sample.sampleId}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.location}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.processedDate}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.time}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.scanMode}
                        </td>

                        <td className='text-center align-middle fw-bold'>
                          {
                            // <SequenceD
                            //   findings={
                            //     sample.graphitizationQuality || ["Inconclusive"]
                            //   }
                            // />
                            sample.graphitizationQuality
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CardA>
        )}
      </div>
      <Footer />
    </div>
  );
}
