import { useState, useEffect, useCallback } from "react";

// react router
import { useParams, Redirect } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// antd
import { Collapse } from "antd";

//react-boostrap
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

// color theme and other ui
import styleDate from "../util/dateStyler";

//constants
import SampleCons from "../constants/sample";

// chart.js
import { Line, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "chart.js/auto";

// api calls
import { useReferenceSpectrum, useSample } from "./../api";
import { useSampleEdit } from "./../api/posts";
import { useDefaultDeviceManufacturer } from "./../api";
import { useUserLocations } from "../api/user";

// context calls
import { useReachbackContext } from "../contexts/ReachbackContext";

// components
import Footer from "./../components/Footer";
import Navbar from "../components/SpectraNavbar";
import { CardA } from "./../components/ui/Cards";
import Notification from "./../components/ui/Notification";
import BandsOffCanvas from "../components/ui/ViewSample/BandsOffCanvas";

import { Tooltip } from "bootstrap";

//add spaces between camel case for analyte names in plot labels
const addSpaces = (string) => {
  return string.replace(/(?!\[)([^A-Z])([A-Z])(?![A-Z])/g, "$1 $2").trim();
};

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

// GRAPH
// graph view component
const { Panel } = Collapse;

const BAR_LABELS = ["D band", "G band", "D' band", "2D band"];
const BAR_COLORS = [
  "rgba(252, 121, 208, 0.3)",
  "rgba(255, 252, 86, 0.3)",
  "rgba(206, 115, 10, 0.3)",
  "rgba(91, 204, 128, 0.3)",
];

// Custom plugin to draw bars that span over multiple x-values
const barSpanPlugin = {
  id: "barSpanPlugin",
  beforeDatasetsDraw(chart, args, options) {
    const { ctx, chartArea, scales } = chart;
    if (!scales.x) {
      console.error("x scale is not defined");
      return;
    }

    const barPositions = [1315, 1580, 1620, 2630];
    const barWidths = [30, 40, 10, 60]; // Widths for each bar

    const canvasWidth = chart.width;
    const canvasHeight = chart.height;

    barPositions.forEach((pos, index) => {
      const x = scales.x.getPixelForValue(pos);

      const width = (barWidths[index] / canvasWidth) * chartArea.width;
      const color = BAR_COLORS[index];

      ctx.save();
      ctx.fillStyle = color;
      ctx.fillRect(
        x - width / 2,
        chartArea.top,
        width,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    });
  },
};

// Register the custom plugin
Chart.register(barSpanPlugin);

const graphOptions = {
  scales: {
    x: {
      title: {
        display: true,
        text: "Raman shift (cm^-1)",
      },
      type: "linear",
    },
    y: {
      title: {
        display: true,
        text: "Intenisty (a.u.)",
      },
    },
  },
  elements: {
    point: {
      radius: 2,
    },
  },

  plugins: {
    legend: {
      align: "end",
      display: true,
      labels: {
        filter: (item) => item.text !== "None",
      },
    },
    barSpanPlugin,
  },
};

// generate graph data
// X labels: wavenumber
// Y data: scan data
function getGraphData(sample) {
  if (!sample) {
    console.log("No scan data:", sample);
    return {
      labels: ["None"],
      datasets: [
        {
          label: "None",
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: [],
        },
      ],
    };
  }

  const data = {};
  const xValues = sample.wavenumber_plot;
  // const xValues = sample[0].wavenumber_plot;

  data.labels = xValues;

  const lineDataset = {
    data: sample.processed_data_plot,
    label: "Scan",
    fill: false,
    borderColor: "#000000",
    backgroundColor: "#000000",
    pointRadius: 1.5,
    hidden: false,
  };

  // Bar plot dataset
  const barDataset = {
    type: "bar",
    label: "None",
    backgroundColor: "rgba(75, 192, 192, 1)",
    hidden: true,
    data: xValues.map((x) => {
      if ([1315, 1580, 1620, 2630].includes(x)) {
        return 1;
      }
      return 0;
    }),
  };

  data.datasets = [lineDataset, barDataset];

  return { data };
}

export default function ViewSample() {
  const { id } = useParams();
  const { data: deviceManufacturer } = useDefaultDeviceManufacturer();
  const [findings, setFindings] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // api
  const {
    data: sample,
    isErrorT,
    isErrorB,
    isSuccess,
    isLoading,
  } = useSample(id);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isErrorT: editFailedT,
    isErrorB: editFailedB,
  } = useSampleEdit(id);

  //hook for fetching the reference spectra
  const {
    results: refData,
    isSuccess: isRefSuccess = false,
    isError: isRefError = false,
    isLoading: isRefLoading,
  } = useReferenceSpectrum([
    { collection: "bulk-burst", analyte: "Graphite Electrode" },
  ]);

  const { data: locations } = useUserLocations();

  // context
  const { submittedErrors } = useReachbackContext();

  // states
  const [submit, setSubmit] = useState("");
  const [description, setDescription] = useState("");
  const [spectraChartData, setSpectraChartData] = useState({});
  const [spectraChartDataLoading, setSpectraChartDataLoading] = useState(true);
  const [isBulkScan, setIsBulkScan] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    console.log("sample:", sample);
  }, [sample]);

  //initialize Booststrap tooltips
  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      const tooltipList = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
      console.log("tooltipTriggerList:", tooltipTriggerList);
      console.log("Tooltips initialized", tooltipList);
      return tooltipList;
    };
    let tooltipList = [];
    if (sample?.otherResults?.length >= 6) {
      const timeoutId = setTimeout(() => {
        tooltipList = initializeTooltips();
      }, 500);

      console.log("Tooltips:", tooltipList);
      return () => {
        clearTimeout(timeoutId);
        tooltipList.forEach((tooltip) => tooltip.dispose());
      };
    }
  }, [sample?.otherResults]);

  //when sample is changed, set state variables with sample data.
  useEffect(() => {
    console.log(locations);
    if (!sample || !locations) {
      console.log("waiting for data");
      return;
    }

    setDescription(sample.description);
    setLocation(
      locations.find((location) => location.locationId === sample.locationId)
        .name
    );
    //set findings list which is used to fetch reference spectra
    let findingsList = sample.tests.flatMap((test) => {
      if (test.rb_findings && test.rb_findings.length > 0) {
        return test.rb_findings.map((finding) => {
          return { collection: test.scanType, analyte: finding.substance };
        });
      }

      return test.findings.map((finding) => {
        return { collection: test.scanType, analyte: finding.substance };
      });
    });
    setFindings(findingsList);
  }, [sample, locations]);

  // returns trace scans if they exist, otherwise returns []
  function traceScans(sample) {
    const traceScansData = sample.tests.filter(
      (test) =>
        test.scanType.toLowerCase() === "trace-burst" ||
        test.scanType.toLowerCase() === "bulk-burst"
    );
    return traceScansData;
  }

  // returns true if this sample HAS trace scans and comes from a wasatch
  const needsGraph = useCallback(() => {
    if (sample) {
      if (traceScans(sample).length > 0 && deviceManufacturer === "wasatch") {
        return true;
      }
    }
    return false;
  }, [sample, deviceManufacturer]);

  // Spectra chart data
  useEffect(() => {
    if (needsGraph() && !isRefLoading && (isRefSuccess || isRefError)) {
      const bulkScansData = sample.tests.filter(
        (test) => test.scanType.toLowerCase() === "bulk-burst"
      );

      let { data: bulkChartData, maxYValue } = getGraphData(bulkScansData[0]); // Pass the entire object

      if (!isRefError && refData.length > 0) {
        //create new array of reference spectrum data with just the necessary info
        const mappedRefData = refData.map((item) => {
          return {
            data: item.data.data,
            label: item.data.label,
            scanType: item.data.scanType,
          };
        });

        let bulkRefData = mappedRefData.filter(
          (item) => item.scanType === "bulk-burst"
        );

        const palette = [
          "#56B4E9",
          "#E69F00",
          "#009E73",
          "#F0E442",
          "#0072B2",
          "#D55E00",
          "#CC79A7",
        ];

        //add reference spectrum data to chart data objects

        bulkRefData = bulkRefData.map((item, index) => {
          return {
            data: item.data,
            label: getAnalyteName(item.label),
            fill: false,
            borderColor: palette[index],
            backgroundColor: palette[index],
            hidden: true,
            pointRadius: 1.5,
          };
        });

        bulkRefData.forEach((item) => bulkChartData.datasets.push(item));
      }

      if (bulkChartData.datasets[0].data.length !== 0) {
        setIsBulkScan(true);
      } else {
        setIsBulkScan(false);
      }

      setSpectraChartData({
        bulk: bulkChartData,
      });

      setSpectraChartDataLoading(false);
    } else {
      // No graph needed, set the chart data to an empty state or some default data.
      setSpectraChartData({
        bulk: getGraphData(null),
      });
      setSpectraChartDataLoading(false);
    }
  }, [sample, isRefSuccess, isRefError, needsGraph]);

  //change analyte name to display name for plot labels
  const getAnalyteName = (analyte) => {
    return (
      SampleCons.ANALYTE_NAMES.find((item) => item.modelName === analyte)
        ?.displayName || addSpaces(analyte)
    );
  };

  console.log("submittedErrors:", submittedErrors); // Log the value

  // reset submit after notification
  useEffect(() => {
    let timer = setTimeout(() => setSubmit(""), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [submit]);

  async function handleSave() {
    if (description !== sample.description) {
      await mutateAsync({ id: id, field: "description", value: description });
    } else {
      setSubmit("success");
    }
    setIsSaved(true);
  }

  //updates submit state and error states when editSuccess or editFailedT/B is true
  useEffect(() => {
    if (editSuccess) {
      setSubmit("success");
    }
    if (editFailedT) {
      setSubmit("error");
    }

    if (editFailedB) {
      setSubmit("error");
    }
  }, [editSuccess, editFailedT, editFailedB]);

  if (isErrorT || isErrorB) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <CardA title='Sample not found' />
        </div>
        <Footer />
      </div>
    );
  }

  if (isLoading === true || (isRefSuccess === false && isRefError === false)) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div
            className='alert mx-3'
            style={{
              backgroundColor: "#7ECBF7",
              fontWeight: "bold",
            }}
            role='alert'
          >
            Fetching sample ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className='flex-container'>
      <Navbar signedIn={false} />
      <div className='flex-body'>
        {isSuccess && (isRefSuccess || isRefError) && (
          <div
            className='w-100 mx-auto py-3 px-sm-3 px-lg-5'
            style={{ marginTop: "79px" }}
          >
            <CardA title='Results Summary'>
              <div className=' my-2'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title text-primary'>
                      Sample Information
                    </h5>
                    <div className=' d-flex flex-wrap'>
                      <div className='px-1 col-12 col-sm-3'>
                        <h6>Sample ID:</h6>
                        <p className='mb-0'>{sample.sampleId}</p>
                      </div>

                      <div className='px-1 col-12 col-sm-3'>
                        <h6>Date:</h6>
                        <div className='mb-0'>
                          {styleDate(sample.processedDate)}
                        </div>
                      </div>
                      <div className='px-1 col-12 col-sm-3'>
                        <h6>Location:</h6>
                        <div className='mb-0'>{location}</div>
                      </div>
                      <div className='px-1 col-12 col-sm-3'>
                        <h6>Scan Mode:</h6>
                        <div className='mb-0'>
                          {sample.tests[0].scanMode.charAt(0).toUpperCase() +
                            sample.tests[0].scanMode.slice(1)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                {/* SAMPLE RESULTS */}
                <div className='card '>
                  <div className='card-body'>
                    <h5 className='card-title text-primary'>Sample Results</h5>
                    <div className='d-flex flex-wrap justify-content-center '>
                      <div className='col-12 mb-1 order-1 w-75'>
                        <div className='border border-3 rounded bg-light fs-6 fw-semibold me-0 p-2'>
                          {" "}
                          Graphitization Quality:{" "}
                          {sample.tests[0].graphite_quality}
                        </div>
                      </div>

                      <div className='col-12 mb-2 order-2 order-xl-3 w-75 '>
                        {isBulkScan && needsGraph() && (
                          <>
                            {spectraChartDataLoading ? (
                              <span>Loading...</span>
                            ) : (
                              <div className='d-flex flex-column mt-3'>
                                <p className='fst-italic'>
                                  Click on the legend item to display/disable
                                  the spectrum.
                                </p>
                                <div className='w-100 align-self-center'>
                                  <Line
                                    data={spectraChartData.bulk}
                                    options={graphOptions}
                                  />
                                  <div className='d-flex justify-content-around'>
                                    <div>
                                      <h6>Raman Bands</h6>

                                      <div className='d-flex '>
                                        {BAR_LABELS.map((label, index) => (
                                          <div
                                            className='d-flex align-items-center justify-content-center'
                                            key={index}
                                          >
                                            <div
                                              className='me-2'
                                              style={{
                                                backgroundColor:
                                                  BAR_COLORS[index],
                                                width: "3rem",
                                                height: "1rem",
                                              }}
                                            ></div>
                                            <div className='me-2'>{label}</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <Button
                                      variant='outline-primary'
                                      onClick={handleShow}
                                    >
                                      More Info
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title text-primary'>Notes</h5>
                    <div className='row'>
                      <div className='form-outline mb-4'>
                        <textarea
                          className='form-control'
                          rows='3'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          style={{
                            border:
                              description !== sample.description
                                ? "1px solid #007BFF"
                                : "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {submit === "success" && (
                <>
                  {console.log("submit success")}
                  {/* <Notification type='success' message='Saved!' duration={3} /> */}
                  {isSaved && <Redirect to='/recent' />}
                </>
              )}
              {submit === "error" && (
                <Notification
                  type='danger'
                  message='Something went wrong, please try again'
                  duration={3}
                />
              )}
              <div className='d-flex flex-row-reverse'>
                <button
                  className='btn btn-primary m-2 w-50'
                  onClick={() => handleSave()}
                >
                  Save
                </button>

                <Link
                  className='btn btn-secondary m-2 d-flex align-items-center justify-content-center w-50'
                  to='/recent'
                >
                  Close
                </Link>
              </div>
            </CardA>
            <BandsOffCanvas show={show} handleClose={handleClose} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
