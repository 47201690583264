import Offcanvas from "react-bootstrap/Offcanvas";

const BandsOffCanvas = ({ show, handleClose }) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => handleClose()}
        scroll={true}
        backdrop={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Key Raman bands and their relationship to graphite
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            <strong>
              D band (≈1350 cm<sup>-1</sup>):
            </strong>{" "}
            Represents disorder-induced peak caused by defects, such as
            vacancies, edges, or grain boundaries, within the graphitic
            structure. The D-band intensity is directly correlated with the
            degree of disorder in the material, where the intensity ratio (D
            band/G band) can be used to estimate graphite quality. For instance,
            there is no D band in perfect graphene material.
          </p>
          <p>
            <strong>
              G band (≈1580 cm<sup>-1</sup>):
            </strong>{" "}
            Arises from the stretching of sp2 hybridized carbons in the
            hexagonal lattice. Its position, shape, and intensity provide
            crucial information about the degree of crystallinity, strain, and
            even the presence of doping within the material. A sharp and intense
            G-band generally indicates a highly ordered, crystalline structure.
            It's the most prominent peak in graphite.
          </p>
          <p>
            <strong>
              D' band (≈1620 cm<sup>-1</sup>):
            </strong>{" "}
            It represents defects in the lattice structure. It appears as a
            shoulder near the G peak. The presence and intensity of the D' band
            can also provide insights into the quality of the graphitization in
            the sample.
          </p>
          <p>
            <strong>
              2D band (≈2700 cm<sup>-1</sup>):
            </strong>{" "}
            It represents the overtone of the D band in sp2 hybridized materials
            that can help distinguish single-layer graphene from multilayer
            graphene (graphite). Frequency can depend on the laser excitation
            energy. Amorphous carbon does not have 2D band.
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BandsOffCanvas;
