import React, { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// global context for state management
import { useSampleContext } from "../../../contexts";
import { useReachbackContext } from "../../../contexts/ReachbackContext";

// components
import { SequenceE } from "../../ui/Sequence";

// queries
import { useGetMeasurementBulk, useValidateSample } from "../../../api";
import { useEffect } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// lodash to help with object comparisons
const some = require("lodash.some");

const WizardBulk = ({ step, steps, changeStep, pageID }) => {
  const [scanResults, setScanResults] = useState([]);
  const [selected, setSelected] = useState([]);
  const [counter, setCounter] = useState(1);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = React.useState("Could not retrieve bulk scan.");
  const [scanLoading, setScanLoading] = useState(false);
  const [unverifiedScan, setUnverifiedScan] = useState();
  const [showModal, setShowModal] = useState(false);

  const [submit, setSubmit] = useState("");
  const [sampleID, setSampleID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [loadingPercent, setLoadingPercent] = useState("0");
  const [startTime, setStartTime] = useState();
  const [scanMode, setScanMode] = useState("");
  const fakeLoading = useRef();
  const history = useHistory();

  const { initWasatchBulk, device, wasatchSample, flow, reset } =
    useSampleContext();
  const { submittedErrorsB, updateSubmittedErrorsB } = useReachbackContext();
  const {
    data: bulkScan,
    status: bulkScanStatus,
    isFetching: isFetchingbulkScan,
    refetch: refetchbulkScan,
  } = useGetMeasurementBulk(
    device?.deviceId,
    "bulk-burst",
    scanMode,
    connectorAppErrorLogger
  );
  const {
    data: validateResponse,
    status: validateStatus,
    isFetching: isFetchingValidate,
    refetch: refetchValidate,
  } = useValidateSample(unverifiedScan, sampleCheckErrorLogger);

  // Effect to refetch bulk scan when scanMode changes
  useEffect(() => {
    if (scanMode) {
      refetchbulkScan();
    }
  }, [scanMode, refetchbulkScan]);

  useEffect(() => {
    console.log("bulkScanStatus:", bulkScanStatus);
  }, [bulkScanStatus]);

  // Helper function to get the two newest scans
  const getTwoNewestScans = (results) => {
    return results.sort((a, b) => b.scanId - a.scanId).slice(0, 2);
  };

  // Initialize the scan results state with the two newest scans
  const [twoNewestScans, setTwoNewestScans] = useState(() =>
    getTwoNewestScans(scanResults)
  );

  // Update the two newest scans whenever scanResults change
  useEffect(() => {
    setTwoNewestScans(getTwoNewestScans(scanResults));
  }, [scanResults]);

  // Helper function to maintain the most recent two scans
  const updateRecentScans = (sequence) => {
    setSelected((prevSelected) => {
      // If there are already two scans, remove the second one before adding the new one
      if (prevSelected.length === 2) {
        return [prevSelected[1], sequence];
      } else {
        return [...prevSelected, sequence];
      }
    });
  };

  const removeT = (sequence) => {
    setSelected(selected.filter((s) => s.scanId !== sequence.scanId));
  };

  function connectorAppErrorLogger(err) {
    console.log("bulk scan error logged.");
    setScanLoading(false);
    setError(true);
    if (err.response?.data?.detail) {
      setErrMsg(
        `Connector App error [${err.response.status}]: ` +
          err.response?.data?.detail
      );
    } else {
      setErrMsg(
        `Connector App error [${err.response.status}]: Could not retrieve bulk scan. Check the Connector App logs.`
      );
    }
    console.error(err);
  }

  function sampleCheckErrorLogger(err) {
    console.log("Error logged.");
    setScanLoading(false);
    setError(true);
    setErrMsg(
      `Model Function error [${err.response.status}]. Please contact a Spectra Plasmonics administrator.`
    );
    console.error(err);
  }

  const handleScan = (mode) => {
    console.log(`Performing ${mode} bulk scan`);
    setScanMode(mode);
    setScanLoading(true);
    setError(false);
    setShowModal(false);
  };

  const handleModal = () => {
    // After completing the scan, show the modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Once the bulk scan is retrieved, prepare it for validation
  useEffect(() => {
    if (!isFetchingbulkScan && step === pageID) {
      if (bulkScanStatus === "success") {
        setUnverifiedScan({ ...bulkScan, scanId: counter });
        setCounter(counter + 1);
      }
    }
  }, [bulkScan, isFetchingbulkScan]);

  // Only attempt to validate scan once react has set the 'unverifiedScan' variable
  useEffect(() => {
    if (unverifiedScan) {
      refetchValidate();
    }
  }, [unverifiedScan]);

  // Check the scan validate query is done
  useEffect(() => {
    if (!isFetchingValidate && step === pageID) {
      if (validateStatus === "success") {
        let verifiedScan = { ...unverifiedScan };

        verifiedScan.verified =
          validateResponse?.data?.errorCheck?.errorFlag === 0;

        // Always add errorCheck properties
        verifiedScan.errorCheck = {
          // errorFlag: subScan?.errorCheck?.errorFlag,
          // errorMessage: subScan?.errorCheck?.errorMessage,
          // errorType: subScan?.errorCheck?.errorType,
          errorFlag: validateResponse?.data?.errorCheck?.errorFlag,
          modelPrediction: validateResponse?.data?.errorCheck?.modelPrediction,
          reconstructionError:
            validateResponse?.data?.errorCheck?.reconstructionError_intensity,
          signalQuality: validateResponse?.data?.errorCheck?.signalQuality,
        };
        console.log(verifiedScan);

        setScanResults([...scanResults, verifiedScan]);
        setScanLoading(false);
        setScanMode("");
        setError(false);
      } else if (validateStatus === "error") {
        console.error("Could not validate scan.");
      }
    }
  }, [validateResponse, isFetchingValidate]);

  /**
   * Reset the summary page (remove the loading bar if necessary) when refreshing
   * the page.
   */
  useEffect(() => {
    if (step === pageID) {
      setLoadingText("");
      setLoadingPercent(0);
    }
  }, [step]);

  useEffect(() => {
    // If the sample submission failed, reset the loading bar
    if (submit === "error") {
      console.log(
        `Sample submission failed in ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(-1);
      setSubmit("");
    }

    // If the sumission was a success and the sampleID was set, prepare to redirect and finished the
    // loading bar
    if (submit === "success" && sampleID) {
      console.log(
        `Sample submission took ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(100);
      let timer = setTimeout(() => {
        setSubmit("");
        history.push(`/sample/${sampleID}`);
        handleReset();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [submit, sampleID, history]);

  if (step !== pageID) {
    return null;
  }

  // // handler function for the "Continue" button click event
  // function handleContinue() {
  //   // update the step to move forward
  //   changeStep("forward");
  // }

  const handleSelectSequence = (sequence) => {
    setSelected([sequence]);
  };

  async function handleSubmit() {
    const summary = {
      // customerId: sample.customerId,
      // locationId: sample.locationId,
      // deviceId: sample.deviceId,
      // description: sample.description,
      // presumedSubstance: wasatchSample.presumed,
      // presumedSubDescription: wasatchSample.presumedSubDescription,
      // tests: wasatchSample.trace,
      // notes: sample.notes,
      // categories: sample.categories,
      // colour: wasatchSample.colour[1],
      // texture: {
      //   id: wasatchSample.texture.id,
      //   description: wasatchSample.texture.description,
      // },
      // reachback: wasatchSample.reachback,
      // sampleId: sample.sampleId,
      // createdBy: sample.createdBy,
      backgroundScan: wasatchSample.background,
      traceScans: selected || [],
      flow: flow,
      // reachback: submittedErrorsT || submittedErrorsB ? true : false,
      // isErrorT: submittedErrorsT ? true : false,
      // isErrorB: submittedErrorsB ? true : false,
      // reachbackComplete:
      //   submittedErrorsT || submittedErrorsB ? "inProgress" : "N/A",
    };

    await axios
      .post("/companion/new-sample/submit-sample", summary)
      .then(function (res) {
        setSampleID(res.data);
        setSubmit("success");
      })
      .catch((error) => handleError(error));
    // submitSample(summary);
    // setSubmit(true);
  }
  function handleError(error) {
    console.log(error);
    setSubmit("error");
  }
  console.log(
    "Submitted Errors:",
    // submittedErrors,
    // submittedErrorsT,
    submittedErrorsB
  );

  const handleReset = () => {
    reset();
  };

  /**
   * Fake function for the loading bar. Recursively calls itself to increase
   * loading bar percentage. Will load, with a degree of randomness. Increases
   * the percentage until just before 100%, usually in around 2-3sec.
   */
  function fakeLoadingBar(percent) {
    // Catch any calls outside the bounds (0 and 100 inclusive)
    if (percent < 0) {
      console.error("Negative percent given to loading bar.");
      updateLoadingBar(0);
      return;
    } else if (percent > 100) {
      console.error("Over 100 percent given to loading bar.");
      updateLoadingBar(100);
      return;
    }

    updateLoadingBar(percent);

    // Increase the percent by 15-34%, and wait between 0.4 & 0.8 sec
    let randPercent = percent + 15 + Math.floor(Math.random() * 20);

    // If the loading bar is about to finish, stop it (wait for server response)
    if (randPercent >= 100) {
      return;
    }
    fakeLoading.current = setTimeout(
      () => fakeLoadingBar(randPercent),
      400 + Math.floor(Math.random() * 400)
    );
  }

  /**
   * Stops the fake loading process.
   */
  function stopFakeLoadingBar() {
    setLoading(false);
    if (fakeLoading.current) {
      clearTimeout(fakeLoading.current);
    }
  }

  /**
   * Sets the loading message to fake details based on the percentage given.
   * @param {*} percent An int between 0 and 100 inclusive.
   */
  function updateLoadingBar(percent) {
    // console.log(`Setting percent to ${percent}`);
    if (percent < 0) {
      percent = 0;
      setLoadingText("Something went wrong. Please try again.");
    } else if (percent < 100) {
      setLoadingText("Sending sample results...");
    } else {
      percent = 100;
      setLoadingText(
        "Sample submitted! You will be redirected in 3 seconds..."
      );
    }
    setLoadingPercent(percent);
  }

  if (step !== pageID) {
    return null;
  }

  return (
    <div className='shadow card d-flex flex-column flex-grow-1 '>
      <div className='card-body full-height justify-content-between'>
        <div
          className={
            twoNewestScans.length !== 0 && !error
              ? "d-flex justify-content-between flex-wrap"
              : "d-flex flex-column justify-content-between flex-grow-1 "
          }
        >
          <div
            className={
              twoNewestScans.length !== 0 && !error ? "col-12 col-md-6" : "row "
            }
          >
            <h5 className='card-title w-100'>
              <span>Conduct Scan</span>
            </h5>

            {twoNewestScans.length === 0 ? (
              <h6 className='card-subtitle mb-1 text-muted'>
                Choose a scan type to begin conducting a scan
              </h6>
            ) : (
              <h6 className='card-subtitle mb-2 text-muted w-100'>
                Please select a scan then click "Save Scan" to continue.
              </h6>
            )}
          </div>

          <div
            className={
              twoNewestScans.length !== 0 && !error
                ? "col-12 col-md-6 d-flex justify-content-start justify-content-md-end align-items-center"
                : " d-flex flex-row flex-grow-1 justify-content-around align-items-center pt-4"
            }
          >
            <div className='col-6 d-flex justify-content-center'>
              <button
                className='btn btn-outline-primary w-75 fs-4'
                style={
                  twoNewestScans.length !== 0 && !error
                    ? { height: "60px" }
                    : { height: "100px" }
                }
                type='button'
                variant='outline-primary'
                onClick={() => handleScan("quick")}
                disabled={scanLoading}
              >
                {scanLoading && scanMode === "quick" ? (
                  <span>
                    <span
                      className='spinner-border spinner-border-sm'
                      aria-hidden='true'
                    ></span>
                    <span> Scanning...</span>
                  </span>
                ) : (
                  <span>Quick Scan</span>
                )}
              </button>
            </div>
            <div className='col-6 d-flex justify-content-center'>
              <button
                className='btn btn-outline-primary w-75 fs-4'
                style={
                  twoNewestScans.length !== 0 && !error
                    ? { height: "60px" }
                    : { height: "100px" }
                }
                type='button'
                variant='outline-primary'
                onClick={() => handleScan("extended")}
                disabled={scanLoading}
              >
                {scanLoading && scanMode === "extended" ? (
                  <span>
                    <span
                      className='spinner-border spinner-border-sm'
                      aria-hidden='true'
                    ></span>
                    <span> Scanning...</span>
                  </span>
                ) : (
                  <span>Extended Scan</span>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Plots */}
        {twoNewestScans.length !== 0 && !error && (
          <form
            className={
              "row justify-content-evenly d-flex align-items-stretch flex-wrap flex-grow-1"
            }
          >
            {bulkScanStatus === "success" &&
              twoNewestScans.map((seq) => {
                return (
                  <div
                    className='col-12 mt-2 spectrum-card-container '
                    key={seq.scanId}
                  >
                    <SequenceE
                      key={seq.scanId}
                      sequence={seq}
                      isSelected={selected.some((s) => s.scanId === seq.scanId)}
                      add={updateRecentScans}
                      remove={removeT}
                      valid={seq.verified}
                      // validateResponse={validateResponse}
                      onSelect={handleSelectSequence}
                    />
                  </div>
                );
              })}
          </form>
        )}
        {loadingText && (
          <div className='mt-4'>
            <p className='text-center'>
              <strong>{loadingText}</strong>
            </p>
            <div className='progress w-75 mx-auto'>
              <div
                className='progress-bar progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: loadingPercent + "%" }}
              ></div>
            </div>
          </div>
        )}

        {/* Back and Continue buttons */}

        {/* <div className="row mt-4">{error && <h6>{errMsg}</h6>}</div> */}
        <div className='row mt-4 align-content-center'>
          <span className='col-6 d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-secondary mx-auto w-75'
              onClick={() => changeStep("back")}
              disabled={bulkScanStatus === "loading"}
            >
              Back
            </button>
          </span>
          <OverlayTrigger
            overlay={
              selected.length === 0 ? (
                <Tooltip id='tooltip-disabled'>
                  Please conduct and select a scan to continue.
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span className='col-6 d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-success mx-auto w-75'
                disabled={submit || loading || selected.length === 0}
                onClick={() => {
                  setLoading(true);
                  setStartTime(new Date().getTime());
                  fakeLoadingBar(0);
                  handleSubmit();
                }}
              >
                Save Scan
              </button>
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default WizardBulk;
